import React from 'react'

import * as styles from '../custom.styles.module.css'


const index = () => {
    return (
        <section className={styles.pageContainer}>
            <div className={styles.pageInnerCo}>

                <div className={styles.videoWrapper}>
                    <video
                        className={styles.fullscreenVideo}
                        src="https://n0xtunjqxyj91w6z.public.blob.vercel-storage.com/valvie-website/videos/promo2C-vGhi8GeAyqaKb0xWZyrJpsE2AEt1z6.mp4"
                        autoPlay
                        loop
                        muted
                        playsInline
                    />
                </div>
            </div>
        </section>
    )
}

export default index